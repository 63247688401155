
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import Permissions, {
  exportPermissionData,
  exportPDFPermissions,
} from "@/core/data/permission";
import {
  Permission,
  getPermissions,
  deletePermission,
} from "@/core/data/permission";
import { useRoute } from "vue-router";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";

export default defineComponent({
  name: "Permission-listing",
  components: {
    Datatable,
  },
  setup() {
    const route = useRoute();
    const checkedPermission = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    const selectedPermission = computed(() => {
      return route.params.id;
    });
    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllPermission = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<Permission>>(Permissions);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);
    const getListItems = function (pageNumber) {
      console.log("selectedPermission.value", selectedPermission.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }
      return new Promise((res, rej) => {
        getPermissions(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedPermission.value.toString()
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Permissions Listing", ["Apps", "Permissions"]);
      getListItems(1);
    });

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedPermission.value.forEach((item) => {
        deletePermission(item);
      });
      checkedPermission.value.length = 0;
    };
    const exportFewSites = () => {
      exportPermissionData(
        selectAllPermission.value ? "all" : checkedPermission.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Permission has been exported.", "success");
      });
    };
    const exportPDFPermissionsHanlder = () => {
      exportPDFPermissions(
        selectAllPermission.value ? "all" : checkedPermission.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Permission has been exported.", "success");
      });
    };
    const deletePermissionItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deletePermission(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your Permission has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addPermission = (item) => {
      if (item.id) {
        router.push({
          name: "apps-permission-role-details",
          params: { id: selectedPermission.value, permissionId: item.id },
        });
      } else {
        router.push({ name: "apps-permission-role-add" });
      }
    };

    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const hasCreate = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasEdit = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const hasDelete = computed(() => {
      return ["ADMIN"].indexOf(userData.value.role) > -1;
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Module name",
          key: "module_name",
          sortable: false,
        },
        {
          name: "Active",
          key: "is_active",
          sortable: false,
        },
        {
          name: "Read",
          key: "read",
          sortable: false,
        },
        {
          name: "Create",
          key: "create",
          sortable: false,
        },
        {
          name: "Update",
          key: "update",
          sortable: false,
        },
        {
          name: "Delete",
          key: "delete",
          sortable: false,
        },
        {
          name: "Show Cost",
          key: "show_cost",
          sortable: false,
        },
      ];
      if (hasEdit.value || hasDelete.value) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });
    const onClickBack = () => {
      router.back();
    };
    return {
      onClickBack,
      addPermission,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deletePermission,
      getPermissions,
      deletePermissionItem,
      search,
      searchItems,
      checkedPermission,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedPermission,
      asyncUser,
      exportFewSites,
      exportPDFPermissionsHanlder,
      selectAllPermission,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
    };
  },
});
